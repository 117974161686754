.our-services {
  background-color: var(--secondary-color);
  padding: 100px 0 70px;
}

.service-item {
  background-color: var(--white-color);
  border-radius: 40px;
  padding: 20px;
  height: calc(100% - 30px);
  margin-bottom: 30px;
}

.service-content {
  padding: 20px;
}

.service-content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--divider-color);
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.service-content-title h2 {
  font-size: 26px;
  width: 100%;
  max-width: 230px;
  text-transform: capitalize;
}

.service-content-title a {
  background-color: var(--accent-color);
  color: var(--white-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
}

.service-content-title a img {
  transition: all 0.3s ease-in-out;
}

.service-item:hover .service-content-title img {
  transform: rotate(45deg);
}

.service-content p {
  margin: 15px 0px;
}

.service-image {
  border-radius: 30px;
  overflow: hidden;
}

.service-image img {
  border-radius: 30px;
  transition: all 0.5s ease-out;
}

.service-item:hover .service-image img {
  transform: scale(1.1);
}
